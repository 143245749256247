/* eslint-disable default-case */
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/auth-context";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { StudentContext } from "../../context/student-context";
import { TeacherContext } from "../../context/teacher-context";
import { TuteurContext } from "../../context/tuteur-context";

import { ContractorContext } from "../../context/contractor-context";

// Components
import Dashboard from "../../components/Dashboard/Dashboard";
import AttendanceListToClose from "../../components/Tabs/General/PlanningTabs/AttendanceListToClose/AttendanceListToClose";

// Student Dashboard Icons
import scheduleIcon from "../../assets/images/calendar2.svg";
import resourcesIcon from "../../assets/images/ressource_administrative.svg";
import docsIcon from "../../assets/images/gestion_etude.svg";
import biblioIcon from "../../assets/images/bibliotheque_digitale.svg";
import emailIcon from "../../assets/images/boite_mail.svg";
import creIcon from "../../assets/images/relation_entreprise.svg";
import slifeIcon from "../../assets/images/vie_etudiante.svg";
import kcommIcon from "../../assets/images/kit_communication.svg";
import newsIcon from "../../assets/images/news.svg";
import financeIcon from "../../assets/images/finance.svg"

// Teacher Dashboard Icons
import adminResourcesIcon from "../../assets/images/manager.svg";
import evaluationIcon from "../../assets/images/exam.svg";
import schoolActivityIcon from "../../assets/images/book.svg";
import classroomIcon from "../../assets/images/education.svg";
import tutorialsIcon from "../../assets/images/tutorial.svg";
import communicationsIcon from "../../assets/images/chat.svg";
import invoicingIcon from "../../assets/images/invoice.svg";
import contactsIcon from "../../assets/images/address-book.svg";

// Styles
import styles from "./Home.module.scss";
import SurveyModal from "../../components/surveyModal/surveyModal";

const Home = () => {
    // Authentication context
    const auth = useContext(AuthContext);

    // Student context
    const studentContext = useContext(StudentContext);

    // Teacher context
    const teacherContext = useContext(TeacherContext);

    // Tuteur context
    const tuteurContext = useContext(TuteurContext);

    // attendanceToClose
    const [attendanceToClose, setAttendanceToClose] = useState(false);

    // Number of surveys
    const [surveyCount, setSurveyCount] = useState(false);

    const [isReady, setIsReady] = useState(true);

    // Contractor context
    const contractorContext = useContext(ContractorContext);

    const { isLoading, error, sendRequest } = useHttpRequest();

    // Context Dashboard
    const [dashboardMenu, setDashboardMenu] = useState();

    // Dashboard Styles
    const [dashboardStyles, setDashboardStyles] = useState();

    const disableStatus = ['alumni', 'sortant'];

    const notificationLocal = localStorage.getItem("studentNotificationAlert");

    // Student Cards Menu Layout
    const studentDashboard = [
        {
            id: "planning_card",
            linkTo: "/planning",
            cardColor: disableStatus.includes(studentContext.studentStatus) ? styles.not_available : styles.schedule,
            icon: scheduleIcon,
            iconColor: styles.icon_white,
            title: "MON EMPLOI DU TEMPS",
            isDisabled: disableStatus.includes(studentContext.studentStatus) ? true : false,
        },
        {
            id: "resources_card",
            linkTo: "/resources",
            cardColor: styles.resources,
            icon: resourcesIcon,
            iconColor: styles.icon_white,
            title: "RESSOURCES ADMIN",
        },
        {
            id: "study_management",
            linkTo: "study-management",
            cardColor: styles.docs,
            icon: docsIcon,
            iconColor: styles.icon_white,
            title: "GESTION DES ÉTUDES",
        },
        {
            id: "digital_resources",
            linkTo: "/digital-resources",
            cardColor: styles.biblio,
            icon: biblioIcon,
            iconColor: styles.icon_white,
            title: "BIBLIOTÈQUE DIGITALE",
        },
        {
            id: "df_card",
            linkTo: "/dossier-financier",
            cardColor: styles.df,
            icon: financeIcon,
            iconColor: styles.icon_white,
            title: "DOSSIER FINANCIER",
        },
        {
            id: "email_card",
            hardLink: true,
            linkTo: "https://mail.google.com/a/groupe-gema.com/mail/#inbox",
            cardColor: styles.email,
            icon: emailIcon,
            iconColor: styles.icon_white,
            title: "MA BOÎTE MAIL",
        },
        {
            id: "careers_card",
            linkTo: "/careers",
            cardColor: styles.cre,
            icon: creIcon,
            iconColor: styles.icon_white,
            title: "CARRIÈRES ENTREPRISES",
            notificationAlert: studentContext.studentOffresRestriction ? false : notificationLocal,
        },
        {
            id: "student_life_card",
            linkTo: "/student-life",
            cardColor: disableStatus.includes(studentContext.studentStatus) ? styles.not_available : styles.slife,
            icon: slifeIcon,
            iconColor: styles.icon_white,
            title: "VIE ASSOCIATIVE & BONS PLANS",
            isDisabled: disableStatus.includes(studentContext.studentStatus) ? true : false,
        },
        {
            id: "comm_kit_card",
            linkTo: "/communication-kit",
            cardColor: styles.kcomm,
            icon: kcommIcon,
            iconColor: styles.icon_white,
            title: "KIT COMM",
        },
        {
            id: "news_card",
            linkTo: "/student-news",
            cardColor: disableStatus.includes(studentContext.studentStatus) ? styles.not_available : styles.news,
            icon: newsIcon,
            iconColor: styles.icon_white,
            title: "NEWS",
            isDisabled: disableStatus.includes(studentContext.studentStatus) ? true : false,
        },
    ];

    // Tuteur Cards Menu Layout
        // Student Cards Menu Layout
        const tuteurDashboard = [
            {
                id: "planning_card",
                linkTo: "/planning",
                cardColor: styles.schedule,
                icon: scheduleIcon,
                iconColor: styles.icon_white,
                title: "MON EMPLOI DU TEMPS",
            },
            {
                id: "resources_card",
                // linkTo: "/resources",
                cardColor: styles.cardDisabled,
                icon: resourcesIcon,
                iconColor: styles.icon_white,
                title: "RESSOURCES ADMIN",
            },
            {
                id: "study_management",
                linkTo: "study-management",
                cardColor: styles.docs,
                icon: docsIcon,
                iconColor: styles.icon_white,
                title: "GESTION DES ÉTUDES",
            },
            {
                id: "digital_resources",
                // linkTo: "/digital-resources",
                cardColor: styles.cardDisabled,
                icon: biblioIcon,
                iconColor: styles.icon_white,
                title: "BIBLIOTÈQUE DIGITALE",
            },
            {
                id: "df_card",
                // linkTo: "/dossier-financier",
                cardColor: styles.cardDisabled,
                icon: financeIcon,
                iconColor: styles.icon_white,
                title: "DOSSIER FINANCIER",
            },
            {
                id: "email_card",
                // hardLink: true,
                // linkTo: "https://mail.google.com/a/groupe-gema.com/mail/#inbox",
                cardColor: styles.cardDisabled,
                icon: emailIcon,
                iconColor: styles.icon_white,
                title: "MA BOÎTE MAIL",
            },
            {
                id: "careers_card",
                linkTo: "/careers",
                cardColor: styles.cre,
                icon: creIcon,
                iconColor: styles.icon_white,
                title: "CARRIÈRES ENTREPRISES",
            },
            {
                id: "student_life_card",
                // linkTo: "/student-life",
                cardColor: styles.cardDisabled,
                icon: slifeIcon,
                iconColor: styles.icon_white,
                title: "VIE ASSOCIATIVE & BONS PLANS",
            },
            {
                id: "comm_kit_card",
                // linkTo: "/communication-kit",
                cardColor: styles.cardDisabled,
                icon: kcommIcon,
                iconColor: styles.icon_white,
                title: "KIT COMM",
            },
            {
                id: "news_card",
                // linkTo: "/student-news",
                cardColor: styles.cardDisabled,
                icon: newsIcon,
                iconColor: styles.icon_white,
                title: "NEWS",
            },
        ];

    // UseEffect pour charger en fond l'émargement si celui ci n'a pas été cloturé
    useEffect(() => {
        if (auth.userType === "teacher") {

            const searchForAttendanceList = async () => {
                const year = teacherContext.teacherCurrentAccountYear.replaceAll("d", "").split("-");

                let beginDate = `${year[0]}-09-01`;
                let endDate = `${year[1]}-09-30`;
    
                // Fetch entire calendar if admin
                if (auth.userRole === "admin") {
                    beginDate = `${year[0]}-09-01`;
                    endDate = `${year[1]}-09-30`;
                }

                try {
                    const url = `${process.env.REACT_APP_API_TEACHER}/planning/attendance-to-close?account_id=${teacherContext.teacherSelectedAccount}&begin=${beginDate}&end=${endDate}`;
                    const attendance = await sendRequest(url, "GET", null, {
                        Authorization: "Bearer " + auth.token,
                    });
                    setIsReady(attendance.length === 1 ? false : true);
                    setAttendanceToClose(attendance);
                } catch (err) {
                    console.log(err);
                }
            }
            searchForAttendanceList();

        } else if (auth.userType === "student") {

            // Asynchronous function to send a request to the backend to get the exact number of active survey from the student.
            const getNumberOfSurvey = async () => {
                try {
                    const url = `${process.env.REACT_APP_API_STUDENT}/documents/get-number-of-survey` // Student attestation = commitment id.
                    const numberOfSurvey = await sendRequest(url, "GET", null, {
                        Authorization: "Bearer " + auth.token,
                    });
                    setSurveyCount(numberOfSurvey);

                } catch (err) {
                    console.log(err)
                }
            }
            getNumberOfSurvey();
        }
    }, [isReady]);

    // Student Cards Menu Layout
    const teacherDashboard = [
        {
            id: "planning_card",
            linkTo: "/planning",
            cardColor: styles.schedule,
            icon: scheduleIcon,
            iconColor: styles.icon_white,
            title: "PLANNING & ÉMARGEMENT",
        },
        {
            id: "evaluation",
            linkTo: "/evaluation",
            cardColor: styles.resources,
            icon: evaluationIcon,
            iconColor: styles.icon_white,
            title: "EVALUATIONS",
        },
        {
            id: "resources_card",
            linkTo: "/admin-resources",
            isDisabled: true,
            cardColor: styles.not_available,
            icon: adminResourcesIcon,
            iconColor: styles.icon_white,
            title: "RESSOURCES ADMIN",
        },
        {
            id: "invoicing_card",
            linkTo: "/invoicing",
            cardColor: teacherContext.teacherIsFreelancer ? styles.biblio : styles.not_available,
            isDisabled: teacherContext.teacherIsFreelancer ? false : true,
            icon: invoicingIcon,
            iconColor: styles.icon_white,
            title: "FACTURATION",
        },
        {
            id: "studentActivity_card",
            linkTo: "/student-activity",
            isDisabled: true,
            cardColor: styles.not_available,
            icon: schoolActivityIcon,
            iconColor: styles.icon_white,
            title: "VIE SCOLAIRE",
        },
        {
            id: "email_card",
            hardLink: true,
            linkTo: "https://mail.google.com/a/groupe-gema.com/mail",
            cardColor: styles.cre,
            icon: emailIcon,
            iconColor: styles.icon_white,
            title: "BOÎTE MAIL",
        },
        {
            id: "communications_card",
            hardLink: true,
            linkTo: "https://mail.google.com/chat/u/0/#chat/welcome",
            cardColor: styles.slife,
            icon: communicationsIcon,
            iconColor: styles.icon_white,
            title: "SALON DE COMM.",
        },
        {
            id: "classroom_card",
            hardLink: true,
            linkTo: "https://classroom.google.com/h",
            cardColor: styles.kcomm,
            icon: classroomIcon,
            iconColor: styles.icon_white,
            title: "CLASSROOM",
        },
        {
            id: "tutos",
            linkTo: "/tutorials",
            cardColor: styles.news,
            icon: tutorialsIcon,
            iconColor: styles.icon_white,
            title: "TUTOS",
        },
        {
            id: "contacts_card",
            linkTo: "/contacts",
            cardColor: styles.faq,
            icon: contactsIcon,
            iconColor: styles.icon_white,
            title: "CONTACTS",
        },
    ];

    const contractorDashboard = [
        {
            id: "invoicing_card",
            linkTo: "/invoicing",
            cardColor: styles.biblio,
            icon: invoicingIcon,
            iconColor: styles.icon_white,
            title: "FACTURATION",
        },
        {
            id: "email_card",
            hardLink: true,
            linkTo: "https://mail.google.com/a/groupe-gema.com",
            cardColor: styles.cre,
            icon: emailIcon,
            iconColor: styles.icon_white,
            title: "BOÎTE MAIL",
        },
        {
            id: "tutos",
            linkTo: "/tutorials",
            cardColor: styles.news,
            icon: tutorialsIcon,
            iconColor: styles.icon_white,
            title: "TUTOS",
        },
        {
            id: "contacts_card",
            linkTo: "/contacts",
            cardColor: styles.faq,
            icon: contactsIcon,
            iconColor: styles.icon_white,
            title: "CONTACTS",
        },
    ];

    useEffect(() => {
        switch (auth.userType) {
            case "student":
                setDashboardMenu(studentDashboard);
                setDashboardStyles(styles.student_card_grid);
                break;
            case "teacher":
                setDashboardMenu(teacherDashboard);
                setDashboardStyles(styles.teacher_card_grid);
                break;
            case "tuteur":
                setDashboardMenu(tuteurDashboard);
                setDashboardStyles(styles.student_card_grid);
                break;
            case "contractor":
                setDashboardMenu(contractorDashboard);
                setDashboardStyles(styles.contractor_card_grid);
                break;
        }
    }, [teacherContext.teacherIsFreelancer, notificationLocal]);

    return (
        <>
        {auth.userType === "teacher" && attendanceToClose.length > 0 && (
            <AttendanceListToClose
                props={attendanceToClose}
                updateEvents={setAttendanceToClose}
                updateReady={setIsReady}
            />
        )}
        {auth.userType === "student" && surveyCount >= 1 && (
            <SurveyModal surveyCount={surveyCount}/>
        )}
            {dashboardMenu && dashboardStyles && (
                <Dashboard
                    dashboardMenu={dashboardMenu}
                    dashboardStyles={dashboardStyles}
                    isReady={isReady}
                />
            )}
        </>
    );
};

export default Home;
