import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { StudentContext } from "../../context/student-context";
import { campusData } from "../../utils/dictionaries";

// Components
import HomeCard from "../HomeCard/HomeCard";
import WebsiteMenu from "../WebsiteMenu/WebsiteMenu";
import NotificationBanner from "../../components/NotificationBanner/NotificationBanner";

// Icons
import warningIcon from "../../assets/images/warning.svg";

// Styles
import styles from "./Dashboard.module.scss";

const Dashboard = (props) => {
  // Authentication context
  const auth = useContext(AuthContext);

  // Student App Context
  const {
    studentFinStatus,
    studentCvecIdentifier,
    studentLevel,
    studentNextYearLevel,
  } = useContext(StudentContext);

  // Ajout d'une variable qui exclura les m2 si ils n'ont pas de nextyearcommitment
  let allowedLevels = true;
  if ((studentLevel == 'm2' && studentNextYearLevel == 'not-assigned') || studentLevel == 'mba_1') {
    allowedLevels = false;
  }

  // History context
  const history = useHistory();

  let notification;

  const dateRentree = {
    b1: {
      74: {
        esi: "Vendredi 23 septembre",
        "ia-school": "Vendredi 30 septembre",
      },
      75: {
        esi: "Mercredi 21 septembre",
        "ia-school": "Vendredi 23 septembre",
      },
      76: {
        esi: "Vendredi 23 septembre",
        "ia-school": "Mercredi 28 septembre",
      },
      77: {
        esi: "Jeudi 29 septembre",
        "ia-school": "Vendredi 30 septembre",
      },
      78: {
        esi: "Jeudi 22 septembre",
        "ia-school": "Jeudi 29 septembre",
      },
      79: {
        esi: "Mercredi 28 septembre",
        "ia-school": "Vendredi 23 septembre",
      },
      80: {
        esi: "Mercredi 21 septembre",
        "ia-school": "Jeudi 22 septembre",
      },
    },
    b2: {
      74: {
        esi: "Vendredi 23 septembre",
        "ia-school": "Vendredi 30 septembre",
      },
      75: {
        esi: "Mercredi 21 septembre",
        "ia-school": "Vendredi 23 septembre",
      },
      76: {
        esi: "Vendredi 23 septembre",
        "ia-school": "Mercredi 28 septembre",
      },
      77: {
        esi: "Jeudi 29 septembre",
        "ia-school": "Vendredi 30 septembre",
      },
      78: {
        esi: "Jeudi 22 septembre",
        "ia-school": "Jeudi 29 septembre",
      },
      79: {
        esi: "Mercredi 28 septembre",
        "ia-school": "Vendredi 23 septembre",
      },
      80: {
        esi: "Mercredi 21 septembre",
        "ia-school": "Jeudi 22 septembre",
      },
    },
    b3: {
      74: {
        esi: "Vendredi 23 septembre",
        "ia-school": "Vendredi 30 septembre",
      },
      75: {
        esi: "Mercredi 21 septembre",
        "ia-school": "Vendredi 23 septembre",
      },
      76: {
        esi: "Vendredi 23 septembre",
        "ia-school": "Mercredi 28 septembre",
      },
      77: {
        esi: "Jeudi 29 septembre",
        "ia-school": "Vendredi 30 septembre",
      },
      78: {
        esi: "Jeudi 22 septembre",
        "ia-school": "Jeudi 29 septembre",
      },
      79: {
        esi: "Mercredi 28 septembre",
        "ia-school": "Vendredi 23 septembre",
      },
      80: {
        esi: "Mercredi 21 septembre",
        "ia-school": "Jeudi 22 septembre",
      },
    },
    m1: {
      74: {
        esi: "Mercredi 21 septembre",
        "ia-school": "Jeudi 22 septembre",
      },
      75: {
        esi: "Jeudi 22 septembre",
        "ia-school": "Vendredi 23 septembre",
      },
      76: {
        esi: "Jeudi 22 septembre",
        "ia-school": "Mercredi 28 septembre",
      },
      77: {
        esi: "Jeudi 29 Septembre",
        "ia-school": "Vendredi 30 septembre",
      },
      78: {
        esi: "Jeudi 22 septembre",
        "ia-school": "Jeudi 29 septembre",
      },
      79: {
        esi: "Mercredi 28 septembre",
        "ia-school": "Vendredi 23 septembre",
      },
      80: {
        esi: "Mercredi 21 septembre",
        "ia-school": "Jeudi 22 septembre",
      },
    },
    m2: {
      74: {
        esi: "Mercredi 28 septembre",
        "ia-school": "Jeudi 29 septembre",
      },
      75: {
        esi: "Jeudi 22 septembre",
        "ia-school": "Vendredi 23 septembre",
      },
      76: {
        esi: "Jeudi 29 septembre",
        "ia-school": "Mercredi 28 septembre",
      },
      77: {
        esi: "Vendredi 30 septembre",
        "ia-school": "Vendredi 30 septembre",
      },
      78: {
        esi: "Jeudi 22 septembre",
        "ia-school": "Jeudi 29 septembre",
      },
      79: {
        esi: "Mercredi 28 septembre",
        "ia-school": "Vendredi 23 septembre",
      },
      80: {
        esi: "Mercredi 21 septembre",
        "ia-school": "Jeudi 22 septembre",
      },
      mba_1: {
        74: {
          esi: "Mercredi 28 septembre",
          "ia-school": "Jeudi 29 septembre",
        },
        75: {
          esi: "Jeudi 22 septembre",
          "ia-school": "Vendredi 23 septembre",
        },
        76: {
          esi: "Jeudi 29 septembre",
          "ia-school": "Mercredi 28 septembre",
        },
        77: {
          esi: "Vendredi 30 septembre",
          "ia-school": "Vendredi 30 septembre",
        },
        78: {
          esi: "Jeudi 22 septembre",
          "ia-school": "Jeudi 29 septembre",
        },
        79: {
          esi: "Mercredi 28 septembre",
          "ia-school": "Vendredi 23 septembre",
        },
        80: {
          esi: "Mercredi 21 septembre",
          "ia-school": "Jeudi 22 septembre",
        },
      },
    },
  };


  if (auth.userType === "student") {
    notification = (
      <NotificationBanner
        bannerIcon={warningIcon}
        bannerIconColor={styles.warning_icon_color}
        message={
          "Attention : En raison d’un problème informatique, une notification de suspension a pu vous être envoyée par erreur. Merci de ne pas tenir compte de cette alerte, aucune action n'est requise de votre part.\n Pour certains, la suspension est effective et suit un traitement spécifique. Nous nous excusons pour ce désagrément et restons disponibles pour toute question."
        }
        // hasBtn={true}
        // btnText={"Dossier Financier"}
        // btnFnc={() => history.push("/dossier-financier")}
      />
    );
    // if (studentFinStatus === "overdue") {
    //   notification = (
    //     <NotificationBanner
    //       bannerIcon={warningIcon}
    //       bannerIconColor={styles.warning_icon_color}
    //       message={
    //         "-- Accès restreint -- Veuillez régulariser votre situation en cliquant ici >>>"
    //       }
    //       hasBtn={true}
    //       btnText={"Dossier Financier"}
    //       btnFnc={() => history.push("/dossier-financier")}
    //     />
    //   );
    // } else if (studentCvecIdentifier === "required") {
    //   notification = (
    //     <NotificationBanner
    //       bannerIcon={warningIcon}
    //       bannerIconColor={styles.warning_icon_color}
    //       message={
    //         "Veuillez effectuer le paiement de la CVEC afin de valider votre dossier administratif pour votre année 2024-2025. Ce document est impératif pour la suite de votre scolarité. "
    //       }
    //       link={"https://cvec.etudiant.gouv.fr"}
    //       hasBtn={false}
    //     />
    //   );
    // }
  } else if (auth.userType === 'tuteur') {
    notification = (
      <NotificationBanner
        bannerIcon={warningIcon}
        bannerIconColor={styles.warning_icon_color}
        message={
          "Vous avez un accès restreint aux espaces Carrières-Entreprises et Gestion des Études de votre apprenti/stagiaire.\n\nPour faire une demande d'absence exceptionnelle sur un jour de cours, merci de compléter le lien suivant : "
        }
        link={"https://forms.gle/BfoXf2KvYXXgkkcS9"}
        hasBtn={false}
      />
    );
  }

  return (
    <article className={styles.container}>
      {notification}
     {/*
      {studentCampusId && studentLevel && studentSchool && allowedLevels && (
        <p className={styles.rentree}>
          Bonjour, nous vous informons que votre rentrée administrative aura
          bien lieu le{" "}
          <strong className={styles.important}>
             SI il y a nextYearCommitment, on se base sur le nextYearLevel pour les date de rentrées *
            {studentNextYearLevel &&
            studentNextYearCampus &&
            studentNextYearLevel != "not-assigned"
              ? dateRentree[studentNextYearLevel][studentNextYearCampus][
                  studentSchool
                ]
              : dateRentree[studentLevel][studentCampusId][studentSchool]}
          </strong>{" "}
          sur le campus de{" "}
          <strong className={styles.important}>
             SI il y a nextYearCommitment, on se base sur le nextYearCampus pour les date de rentrées 
            {studentNextYearLevel &&
            studentNextYearCampus &&
            studentNextYearCampus != "not-assigned"
              ? campusData[studentNextYearCampus].city
              : campusData[studentCampusId].city}
          </strong>
          . Pour rappel, votre présence est{" "}
          <strong className={styles.important}>obligatoire</strong>.
        </p>
            )} */}
      <section className={props.dashboardStyles}>
        {props.dashboardMenu &&
          props.dashboardMenu.map((input) => {
            return (
              <HomeCard
                id={input.id}
                key={input.id}
                linkTo={input.linkTo}
                hardLink={input.hardLink || false}
                isDisabled={(input.isDisabled || !props.isReady) || false}
                isEmpty={input.isEmpty || false}
                cardColor={props.isReady ? input.cardColor : styles.not_available}
                icon={input.icon}
                iconColor={input.iconColor}
                title={input.title}
                notificationAlert={input.notificationAlert}
              />
            );
          })}
      </section>
      <section className={styles.websites_container}>
        {props.websitesMenu &&
          props.websitesMenu.map((input) => {
            return (
              <WebsiteMenu
                id={input.id}
                key={input.id}
                linkTo={input.linkTo}
                logoSize={input.logoSize}
                logo={input.logo}
                altText={input.altText}
                anchorLabel={input.anchorLabel}
              />
            );
          })}
      </section>
    </article>
  );
};

export default Dashboard;
