import React, { useState, useContext } from "react";

import { AuthContext } from "../../context/auth-context";
import { StudentContext } from "../../context/student-context";

// Components
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import TabView from "../../components/TabView/TabView";
import Absences from "../../components/Tabs/Student/Absences/Absences";
import Grades from "../../components/Tabs/Student/Grades/Grades";
import SchoolReports from "../../components/Tabs/Student/SchoolReports/SchoolReports";
import SchoolCertifications from "../../components/Tabs/Student/SchoolCertifications/StudentCertifications";

// Icons
import studiesManagementIcon from "../../assets/images/gestion_etude.svg";

// Styles
import styles from "./StudiesManagement.module.scss";
import Lateness from "../../components/Tabs/Student/Lateness/Lateness";

const StudiesManagement = () => {

    // Authentication context
    const auth = useContext(AuthContext);

    const studentContext = useContext(StudentContext);

    // Menu Tabs Buttons
    const [studiesManagementNav] = useState([
        {
            id: "absences",
            btnText: "Mes absences",
            btnType: "text",
        },
        {
            id: "lateness",
            btnText: "Mes retards",
            btnType: "text",
        },
        {
            id: "grades",
            btnText: "Mes Notes",
            btnType: "text",
        },
        {
            id: "school_reports",
            btnText: "Mes bulletins",
            btnType: "text",
        },
        {
            id: "school_certifications",
            btnText: "Certifications",
            btnType: "text",
            disabled: auth.userType === "tuteur" ? true : false
        },
    ]);

    // Tab Views
    const [tabMenus] = useState([
        {
            id: "absences",
            view: <Absences />,
        },
        {
            id: "lateness",
            view: <Lateness />,
        },
        {
            id: "grades",
            view: <Grades />,
        },
        {
            id: "school_reports",
            view: <SchoolReports />,
        },
        {
            id: "school_certifications",
            view: <SchoolCertifications />,
        },
    ]);

    return (
        <>
            {tabMenus && studiesManagementNav && (
                <main className="tab_container">
                    <HeaderBanner
                        title="Gestion des études"
                        icon={studiesManagementIcon}
                        iconColor={styles.icon_color}
                        bgColor={styles.banner_color}
                    />
                    <TabView
                        menuConfig={studiesManagementNav}
                        tabViews={tabMenus}
                        activeBtnColor={styles.btn_active}
                    />
                </main>
            )}
        </>
    );
};

export default StudiesManagement;
