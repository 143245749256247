import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { fixWindowPosition } from "../../utils/helperFunctions";
import ActionBtn from "../Buttons/ActionBtn/ActionBtn";
import ModalHeader from "../ModalHeader/ModalHeader";

import styles from "./surveyModal.module.scss";

const SurveyModal = (props) => {
    
    const [surveyNumbers] = useState(props.surveyCount);
    const history = useHistory();

    const [displayModal, setDisplayModal] = useState(true);

    const displayText = surveyNumbers > 1 ? surveyNumbers + " nouveaux questionnaires à remplir" : "Un nouveau questionnaire à remplir"
    
    return (
      <>
        {displayModal && (
          <article className={styles.modal_container}>
                <ModalHeader
                  headerColor={styles.banner_color}
                  headerTitle="Informations importantes"
                  closeBtn={() => setDisplayModal(false)}
                  s
                />
              <div className={styles.modalCore}>
                <div className={styles.paragraphsStyles}>
                  <Link className={styles.link} onClick={() => history.push("/resources")}>[{displayText}]</Link>
                   <p className={styles.infoP}>Vous pouvez les retrouver dans votre onglet RESSOURCES ADMINISTRATIVES puis QUESTIONNAIRES ou en cliquant sur le lien ci-dessus.</p>
                </div>
              </div>
          </article>
        )}
      </>
    );
}

export default SurveyModal;