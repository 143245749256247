import React, { useState, useContext, useEffect } from "react";
import { useHttpRequest } from "../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../context/auth-context";
import { StudentContext } from "../../../../context/student-context";

// Components
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../../HttpMessagePrompt/HttpMessagePrompt";
import ListHeader from "../../../ListHeader/ListHeader";
import AbsencesList from "../../../Tabs/Student/AbsencesList/AbsencesList";

// Styles
import styles from "./Lateness.module.scss";
import { TuteurContext } from "../../../../context/tuteur-context";

const Lateness = (props) => {

    // Authentication context
    const auth = useContext(AuthContext);

    // Teacher context
    const context = useContext(auth.userType === 'student' ? StudentContext : TuteurContext);

    // Backend Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    // Fetched Student Absences
    const [studentLateness, setStudentLateness] = useState();



    // Fetch Absences
    useEffect(() => {
        const fetchLateness = async () => {
            try {
                const url = `${process.env.REACT_APP_API_HOST}/planning/absences/${auth.userType === 'student' ? context.studentCurrentPeriod : context.tuteurCurrentPeriod}?type=lateness`;
                const lateness = await sendRequest(url, "GET", null, {
                  Authorization: "Bearer " + auth.token,
                });
                if (lateness[1]) delete lateness[1] // CSRF Not needed here.

                setStudentLateness(lateness[0]);
            } catch (err) {
                console.error(err);
            }
        };
        fetchLateness();
    }, []);

    return (
      <>
        {isLoading && (
          <div className="spinner">
            <LoadingSpinner />
          </div>
        )}
        {error && (
          <div className={styles.subheader_wrapper}>
            <HttpMessagePrompt error={error} />
          </div>
        )}
        {!isLoading &&
          !error &&
          studentLateness &&
          studentLateness.length === 0 && (
            <div className={styles.subheader_wrapper}>
              <HttpMessagePrompt
                error={false}
                message="Vous n'avez pas de retards !"
              />
            </div>
          )}
        {!isLoading &&
          !error &&
          studentLateness &&
          studentLateness.length !== 0 && (
            <div className={styles.subheader_wrapper}>
              <ListHeader
                title={"Votre historique de retards"}
                message={"pour l'année en cours"}
              />
              <AbsencesList absences={studentLateness} eventType={"lateness"} />
            </div>
          )}
      </>
    );
};

export default Lateness;
